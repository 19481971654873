import React, { useContext, useState } from 'react';
import { Edit, Add, Upload, Close, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { Grid, IconButton, Typography, Box } from '@mui/material';
import { toastWarning } from 'utils/toast';
import { dropTipoServico } from 'utils/drops';
import { findOnArray } from 'utils/functions';
import { useDialog, useModal } from 'components/Modals';
import EntidadeModal from 'components/Modals/EntidadeModal';
import EquipamentoModal from 'components/Modals/EquipamentoModal';
import FullEquipamentoModal from 'components/Modals/FullEquipamentoModal';
import PesquisaEquipamentoModal from 'components/Modals/PesquisaEquipamentoModal';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import api from 'services/api';
import moment from 'moment';

const Equipamento = () => {
  const titulo = 'Ordem de Serviço';
  const defaultValues = {
    cadastro_id: null,
    tecnico_id: null,
    tipo: 'CORRETIVA',
    sn: '',
    equipamento: null,
    relato: '',
    acessorios: '',
  };
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const { openDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { control, handleSubmit, watch, setValue } = useForm({ defaultValues });
  const { postLoading, postDocumento, postDocumentoMidia } =
    useContext(DocumentosContext);
  const { drops } = useContext(DropsContext);
  const { user } = useContext(AuthContext);

  const onChangeEntidade = async (v) => {
    if (v) {
      const params = { entidade_id: v?.value, tipo: 'CLIENTE' };
      const { data } = await api.get(`/Cadastros/Entidade/Complemento`, {
        params,
      });
      if (data?.tecnico_id) {
        setValue('tecnico_id', data?.tecnico_id);
      }
    }
  };

  const onSubmit = (values) => {
    if (!Boolean(values?.cadastro_id)) {
      return toastWarning('Entidade obrigatória');
    } else if (!Boolean(values?.equipamento)) {
      return toastWarning('Equipamento obrigatório');
    } else {
      const data = {
        Documento: {
          especie_id: 16,
          natureza_operacao_id: 11,
          filial_id: user?.filial_id,
          cadastro_id: values?.cadastro_id,
          dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
          users_id: user?.id,
        },
        Servico: {
          tipo: values?.tipo,
          tecnico_id: values?.tecnico_id,
        },
        Equipamentos: [
          {
            equipamento_id: values?.equipamento,
            sn: values?.sn,
            relatocli: values?.relato,
            acessorios: values?.acessorios,
          },
        ],
        Itens: [
          {
            sequencia: 1,
            descricao: `Manutenção`,
            quantidade: 1,
            Processos: [
              {
                operacao: 10,
                descricao: `Manutenção`,
                apontamento: 'SIM',
              },
            ],
          },
        ],
        Registros: [
          {
            users_id: user?.id,
            datahora: moment().format('YYYY-MM-DD HH:mm:ss'),
            status_id: Boolean(values?.tecnico_id) ? 45 : 16,
          },
        ],
      };
      if (Boolean(file)) {
        const formData = new FormData();
        formData.append('documento', JSON.stringify(data));
        formData.append('midia', file);
        postDocumentoMidia({ data: formData, cb: () => navigate(-1) });
      } else {
        postDocumento({ data, cb: () => navigate(-1) });
      }
    }
  };

  const onSearchEquipamento = (values) => {
    setValue('sn', values?.sn);
    setValue('equipamento', values?.equipamento_id);
    closeModal();
  };

  return (
    <Container>
      <Header titulo={titulo} />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" alignItems="center">
            <Box flex={1}>
              <VirtualDrop
                name="cadastro_id"
                control={control}
                label="Entidade"
                options={drops?.Entidade}
                onValueChange={onChangeEntidade}
                refresh="Entidade"
              />
            </Box>
            <IconButton
              color="primary"
              disabled={!Boolean(watch('cadastro_id'))}
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                    'cadastro_id'
                  )}`
                )
              }
            >
              <Visibility />
            </IconButton>
            <IconButton
              color="primary"
              disabled={!Boolean(watch('cadastro_id'))}
              onClick={() =>
                openDialog(
                  <EntidadeModal
                    entidade_id={watch('cadastro_id')}
                    callback={(id) => setValue('cadastro_id', id)}
                  />,
                  'Editar Entidade'
                )
              }
            >
              <Edit />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() =>
                openDialog(
                  <EntidadeModal
                    callback={(id) => setValue('cadastro_id', id)}
                  />,
                  'Cadastrar Entidade'
                )
              }
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="tecnico_id"
              control={control}
              label="Técnico"
              options={drops?.Users?.filter((f) => f?.nivel === 'TÉCNICO')}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="tipo"
              control={control}
              label="Tipo do Serviço"
              options={dropTipoServico}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" align="center">
              Dados do Equipamento
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                openModal(
                  <PesquisaEquipamentoModal onSubmit={onSearchEquipamento} />,
                  90
                )
              }
            >
              Pesquisar Equipamento
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="sn" control={control} label="Serial Number" />
          </Grid>
          <Grid item xs={10} sm={8} display="flex" alignItems="center">
            <Box flex={1}>
              <VirtualDrop
                name="equipamento"
                control={control}
                label="Equipamento"
                options={drops?.Equipamento}
              />
            </Box>
            <IconButton
              color="primary"
              disabled={!Boolean(watch('equipamento'))}
              onClick={() =>
                openDialog(
                  <EquipamentoModal
                    equipamento={findOnArray(
                      watch('equipamento'),
                      drops?.Equipamento
                    )}
                    callback={(id) => setValue('equipamento', id)}
                  />,
                  'Editar Equipamento'
                )
              }
            >
              <Edit />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() =>
                openDialog(
                  <FullEquipamentoModal
                    callback={(id) => setValue('equipamento', id)}
                  />,
                  'Cadastrar Equipamento'
                )
              }
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Input name="relato" control={control} label="Relato do Cliente" />
          </Grid>
          <Grid item xs={12}>
            <Input name="acessorios" control={control} label="Acessórios" />
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Upload />}
              onClick={() => document.getElementById('upload-midia')?.click()}
            >
              <input
                id="upload-midia"
                hidden
                accept="image/*"
                type="file"
                onChange={({ target }) => {
                  setFile(target.files[0] || null);
                  target.value = '';
                }}
              />
              Upload
            </Button>
            {Boolean(file) && (
              <Typography
                variant="body2"
                sx={{ marginLeft: 1, color: 'primary.main' }}
              >
                {file?.name}
              </Typography>
            )}
            {Boolean(file) && (
              <IconButton onClick={() => setFile(null)} size="small">
                <Close />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Finalizar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Equipamento;
