const mock = ({ documento = {} }) => {
  return {
    tipoComplemento:
      documento?.NaturezaOperacao?.operacao === 'ENTRADA'
        ? 'FORNECEDOR'
        : 'CLIENTE',
    showEntidade: documento?.Especie?.entidade !== 'NAO',
    showLocalEstoque:
      documento?.Especie?.descricao === 'INVENTARIO' ||
      documento?.Especie?.descricao === 'MOVIMENTAÇÃO DE ESTOQUE' ||
      documento?.Especie?.natureza === 'COMPRA' ||
      documento?.Especie?.natureza === 'VENDA' ||
      documento?.Especie?.natureza === 'FATURA' ||
      documento?.NaturezaOperacao?.natureza === 'SERVICOS',
    showContribuinte: documento?.Especie?.natureza !== 'SERVICO',
    showDocumento: documento?.Especie?.modelofiscal_id === 33,
    isEntrada: documento?.Especie?.grupo === 'ENTRADA',
    isSaida:
      documento?.Especie?.grupo === 'SAIDA' &&
      documento?.Especie?.descricao !== 'MDF-e' &&
      documento?.Especie?.natureza !== 'ESTOQUE',
    isPedSaida: documento?.especie_id === 9,
    isPedComp: documento?.especie_id === 4,
    isFatura: documento?.Especie?.natureza === 'FATURA',
    isImportacao: documento?.NaturezaOperacao?.natureza?.includes('IMPORTACAO'),
    isExportacao: documento?.NaturezaOperacao?.natureza?.includes('EXPORTACAO'),
    isContrato: documento?.Especie?.natureza === 'CONTRATO',
    isServico: documento?.Especie?.natureza === 'SERVICO',
  };
};

export default mock;
