import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Add, Delete, Edit, QueryStats, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import {
  checkInfosProduto,
  findOnArray,
  summarizer,
  getDatePagto,
} from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog, useModal } from 'components/Modals';
import PesquisaEquipamentoModal from 'components/Modals/PesquisaEquipamentoModal';
import FullEquipamentoModal from 'components/Modals/FullEquipamentoModal';
import EquipamentoModal from 'components/Modals/EquipamentoModal';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import Blank from 'assets/blank.png';
import api from 'services/api';
import ItemModal from './Modals/ItemModal';
import PagamentoModal from './Modals/PagamentoModal';
import moment from 'moment';
import styles from './styles';

const Gerar = () => {
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { openModal, closeModal } = useModal();
  const { postDocumento, postLoading } = useContext(DocumentosContext);
  const defaultValues = {
    documento: '',
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    filial_id: user?.filial_id,
    cadastro_id: null,
    vendedor_id: null,
    tecnico_id: null,
    tecnico: null,
    entidade_endereco_id: null,
    observacao: '',
    dadosadc: '',
    haver: '',
    haver_uso: false,
    //EQUIPAMENTO
    sn: '',
    equipamento_id: null,
    relatocli: '',
    acessorios: '',
    //ITENS
    produto_id: null,
    descricao: '',
    quantidade: '',
    unidade_medida_id: null,
    referencia: '',
    desconto1_pc: '',
    desconto_vlr: '',
    preco: '',
    przent: null,
    infadc: '',
    itens: [
      {
        descricao: 'Manutenção',
        quantidade: 1,
        desconto1_pc: 0,
        desconto_vlr: 0,
        preco: 0,
        subtotal: 0,
        Processos: [
          { operacao: 10, descricao: 'Manutenção', apontamento: 'SIM' },
        ],
      },
    ],
    //PAGAMENTO
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    fixar: false,
    pagamento: [],
  };
  const navigate = useNavigate();
  const [additionals, setAdditionals] = useState(null);
  const [loading, setLoading] = useState(false);
  const { openDialog, closeDialog } = useDialog();
  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  const subtotal =
    (watch('quantidade') || 0) * (watch('preco') || 0) +
    (watch('desconto_vlr') || 0);

  const onSearchEquipamento = (values) => {
    setValue('sn', values?.sn);
    setValue('equipamento_id', values?.equipamento_id);
    closeModal();
  };

  const getAdditionals = async (v) => {
    const produto_id = v?.value;
    if (Boolean(produto_id)) {
      setLoading(true);
      const produto = await checkInfosProduto({
        produto_id,
        disponivel: 'SIM',
        filial_id: watch('filial_id'),
        vendedor_id: watch('vendedor_id'),
        cadastro_id: watch('cadastro_id'),
        condicao_pagamento_id: watch('condicao_pagamento_id'),
      });
      setAdditionals(produto);
      setValue('descricao', produto?.descricao);
      setValue('desconto1_pc', produto?.desconto1_pc);
      setValue('quantidade', produto?.quantidade || 1);
      setValue('unidade_medida_id', produto?.unidade_medida_id);
      setValue('referencia', produto?.referencia);
      setValue('preco', produto?.preco);
      setLoading(false);
    }
  };

  const onAddItem = (values) => {
    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue('itens', [
      ...values?.itens,
      {
        subtotal,
        produto_id: values?.produto_id,
        descricao: values?.descricao,
        quantidade: values?.quantidade,
        unidade_medida_id: values?.unidade_medida_id,
        referencia: values?.referencia,
        desconto1_pc: values?.desconto1_pc,
        desconto_vlr: values?.desconto_vlr,
        preco: values?.preco,
        przent: values?.przent,
        infadc: values?.infadc,
      },
    ]);
    setAdditionals(null);
    resetField('produto_id');
    resetField('descricao');
    resetField('quantidade');
    resetField('unidade_medida_id');
    resetField('referencia');
    resetField('desconto1_pc');
    resetField('desconto_vlr');
    resetField('preco');
    resetField('przent');
    resetField('infadc');
    handleSubmit(onRecalc)();
  };

  const onEditItem = ({ values, index }) => {
    const itens = watch('itens');
    const item = itens[index];
    if (values?.quantidade > item?.quantidade_max) {
      return toastWarning(
        `Quantidade limite excedida. (Limite: ${item?.quantidade_max})`
      );
    }
    if (Boolean(item?.preco_max) && values?.preco > item?.preco_max) {
      return toastWarning(
        `Preço limite excedido. (Limite: ${item?.preco_max})`
      );
    }

    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue(
      'itens',
      itens?.map((itm, i) =>
        index === i ? { ...itm, ...values, subtotal } : itm
      )
    );
    handleSubmit(onRecalc)();
    closeDialog();
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
    handleSubmit(onRecalc)();
  };

  const onChangePagto = (values) => {
    const forma_pagto_id = values?.forma_pagto_id;
    const condicao_pagamento_id = values?.condicao_pagamento_id;
    const fixar = values?.fixar;
    if (Boolean(forma_pagto_id) && Boolean(condicao_pagamento_id)) {
      const condicoes =
        findOnArray(forma_pagto_id, drops?.FormaDePagamento, 'condicoes') || [];
      const cond_pagto = findOnArray(condicao_pagamento_id, condicoes);
      let valor_total = summarizer(values?.itens, 'subtotal');
      const pagamento = [];
      if (values?.haver_uso && valor_total > 0) {
        const forma_pagto_id = drops?.FormaDePagamento?.find(
          (f) => f?.modulo === 'VENDAS' && f?.modalidade === '05-CRÉDITO LOJA'
        )?.value;
        const valor = values?.haver > valor_total ? valor_total : values?.haver;
        pagamento?.push({
          dtvenc: moment().format('YYYY-MM-DD'),
          forma_pagto_id,
          valor,
        });
        valor_total -= valor;
      }
      if (cond_pagto && valor_total > 0) {
        let valor_lancado = 0;
        for (let parcela = 1; parcela <= cond_pagto?.nvezes; parcela++) {
          const valor = parseFloat(
            (valor_total / cond_pagto?.nvezes).toFixed(2)
          );
          pagamento.push({
            dtvenc: getDatePagto({
              ...cond_pagto,
              parcela,
              fixar,
              start_date: values?.dtemissao,
            }),
            valor:
              parcela === cond_pagto?.nvezes
                ? valor_total - valor_lancado
                : valor,
            forma_pagto_id,
          });
          valor_lancado += valor;
        }
      }
      setValue(
        'pagamento',
        pagamento?.map((p, i) => ({ ...p, parcela: i + 1 }))
      );
    }
  };

  const onEditPagto = ({ values, index }) => {
    const pagamento = watch('pagamento');
    setValue(
      'pagamento',
      pagamento?.map((p, i) =>
        index === i
          ? { ...p, ...values, parcela: i + 1 }
          : { ...p, parcela: i + 1 }
      )
    );
    closeDialog();
  };

  const onDeletePagto = (index) => {
    setValue(
      'pagamento',
      watch('pagamento')?.filter((_, i) => i !== index)
    );
  };

  const onRecalc = (values) => {
    const valor_total = summarizer(values?.itens, 'subtotal');
    const parcelas = values?.pagamento?.length;
    if (Boolean(parcelas) && valor_total > 0) {
      let valor_lancado = 0;
      const pagamento = values?.pagamento.map((p, i) => {
        let valor =
          parcelas === i + 1
            ? valor_total - valor_lancado
            : parseFloat((valor_total / parcelas).toFixed(2));
        valor_lancado += valor;
        return { ...p, valor };
      });
      setValue('pagamento', pagamento);
    } else {
      setValue('pagamento', []);
    }
  };

  const onChangeEntidade = async (v) => {
    if (v) {
      const params = { entidade_id: v?.value, tipo: 'CLIENTE' };
      const { data } = await api.get(`/Cadastros/Entidade/Complemento`, {
        params,
      });
      if (data?.tecnico_id) {
        setValue('tecnico_id', data?.tecnico_id);
      }
      if (data?.vendedor_id) {
        setValue('vendedor_id', data?.vendedor_id);
      }
      if (data?.condicao_pagamento_id) {
        setValue('condicao_pagamento_id', data?.condicao_pagamento_id);
      }
      if (data?.forma_pagto_id) {
        setValue('forma_pagto_id', data?.forma_pagto_id);
      }
      if (data?.haver) {
        setValue('haver', data?.haver);
      }
    } else {
      setValue('haver', null);
      setValue('haver_uso', false);
    }
    setValue('entidade_endereco_id', null);
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        especie_id: 8,
        natureza_operacao_id: 11,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: values?.dtemissao,
        documento: values?.documento,
        observacao: values?.observacao,
        dadosadc: values?.dadosadc,
      },
      Saida: {
        condicao_pagamento_id: values?.condicao_pagamento_id,
        vendedor_id: values?.vendedor_id,
        entidade_endereco_id: values?.entidade_endereco_id,
        dtsaida: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      Servico: {
        tipo: 'CORRETIVA',
        tecnico_id: values?.tecnico_id,
      },
      Equipamentos: [
        {
          equipamento_id: values?.equipamento_id,
          sn: values?.sn,
          relatocli: values?.relatocli,
          acessorios: values?.acessorios,
        },
      ],
      Registros: [{ status_id: Boolean(values?.tecnico_id) ? 45 : 1 }],
      Itens: values?.itens?.map((i, index) => ({
        ...i,
        sequencia: index + 1,
      })),
      Financeiros: values?.pagamento,
    };
    postDocumento({ data, cb: () => navigate(-1) });
  };

  return (
    <Container>
      <Header titulo="Gerar Orçamento" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card title="Informações Gerais">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Input
                  name="documento"
                  control={control}
                  label="Nº do Documento"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputMask
                  name="dtemissao"
                  control={control}
                  label="Data de Emissão"
                  type="datetime"
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="filial_id"
                  control={control}
                  label="Filial"
                  options={drops?.Filial}
                />
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center">
                <Box flex={1}>
                  <VirtualDrop
                    name="cadastro_id"
                    control={control}
                    label="Entidade"
                    options={drops?.Entidade}
                    onValueChange={onChangeEntidade}
                    refresh="Entidade"
                  />
                </Box>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() =>
                    window.open(
                      `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                        'cadastro_id'
                      )}`
                    )
                  }
                  disabled={!Boolean(watch('cadastro_id'))}
                >
                  <Visibility fontSize="small" />
                </IconButton>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() =>
                    window.open(
                      `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                        'cadastro_id'
                      )}`
                    )
                  }
                  disabled={!Boolean(watch('cadastro_id'))}
                >
                  <QueryStats fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="vendedor_id"
                  control={control}
                  label="Vendedor"
                  options={drops?.EntidadeTipo?.filter(
                    (f) => f?.tipo === 'VENDEDOR' || f?.tipo === 'REPRESENTANTE'
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="tecnico_id"
                  control={control}
                  label="Técnico"
                  options={drops?.Users?.filter((f) => f?.nivel === 'TÉCNICO')}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="entidade_endereco_id"
                  control={control}
                  label="Local de Fornecimento"
                  options={drops?.EntidadeEndereco?.filter(
                    (e) => e?.entidade_id === watch('cadastro_id')
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name="observacao"
                  control={control}
                  label="Observação"
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name="dadosadc"
                  control={control}
                  label="Dados Adicionais"
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card title="Informações do Equipamento">
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    openModal(
                      <PesquisaEquipamentoModal
                        onSubmit={onSearchEquipamento}
                      />,
                      90
                    )
                  }
                >
                  Pesquisar Equipamento
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Input name="sn" control={control} label="Serial Number" />
              </Grid>
              <Grid item xs={10} sm={8} display="flex" alignItems="center">
                <Box flex={1}>
                  <VirtualDrop
                    name="equipamento_id"
                    control={control}
                    label="Equipamento"
                    options={drops?.Equipamento}
                  />
                </Box>
                <IconButton
                  color="primary"
                  disabled={!Boolean(watch('equipamento_id'))}
                  onClick={() =>
                    openDialog(
                      <EquipamentoModal
                        equipamento={findOnArray(
                          watch('equipamento_id'),
                          drops?.Equipamento
                        )}
                        callback={(id) => setValue('equipamento_id', id)}
                      />,
                      'Editar Equipamento'
                    )
                  }
                >
                  <Edit />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() =>
                    openDialog(
                      <FullEquipamentoModal
                        callback={(id) => setValue('equipamento_id', id)}
                      />,
                      'Cadastrar Equipamento'
                    )
                  }
                >
                  <Add />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="relatocli"
                  control={control}
                  label="Relato do Cliente"
                />
              </Grid>
              <Grid item xs={12}>
                <Input name="acessorios" control={control} label="Acessórios" />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card title="Itens">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} display="flex">
                <Card style={styles?.card}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <VirtualDrop
                        name="produto_id"
                        control={control}
                        label="Produto"
                        options={drops?.Produto}
                        onValueChange={getAdditionals}
                        refresh="Produto"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        name="descricao"
                        control={control}
                        label="Descrição"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputMask
                        name="quantidade"
                        control={control}
                        label="Quantidade"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Dropdown
                        name="unidade_medida_id"
                        control={control}
                        label="Unidade de Medida"
                        options={drops?.UnidadeMedida}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Input
                        name="referencia"
                        control={control}
                        label="Referência"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputMask
                        name="desconto1_pc"
                        control={control}
                        label="% Desconto"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputMask name="preco" control={control} label="Preço" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputMask
                        name="przent"
                        control={control}
                        label="Prazo de Entrega"
                        type="date"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        name="infadc"
                        control={control}
                        label="Informações Adicionais"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                      <Box
                        component="img"
                        sx={styles?.image}
                        src={additionals?.imagem || Blank}
                      />
                    </Grid>
                    {Boolean(loading) ? (
                      <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                        <Loader />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6} {...styles?.textDetailsProps}>
                        <Typography variant="h6" color="primary">
                          {additionals?.descricao}
                        </Typography>
                        <Typography variant="body1">
                          Referência: {additionals?.referencia || '-'}
                        </Typography>
                        <Typography variant="body1">
                          Saldo: {additionals?.saldo || '0'}{' '}
                          {additionals?.UnidadeMedida?.descricao || ''}
                        </Typography>
                        <Typography variant="body1">
                          Localização: {additionals?.localizador || '-'}
                        </Typography>
                        <Typography variant="h6" align="right" color="primary">
                          {subtotal?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} textAlign="center">
                      <Button
                        variant="contained"
                        color="primary"
                        loading={loading}
                        onClick={handleSubmit(onAddItem)}
                      >
                        ADICIONAR
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} display="flex">
                <Card style={styles?.card}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Referência</TableCell>
                              <TableCell>Descrição</TableCell>
                              <TableCell>Quantidade</TableCell>
                              <TableCell>Desconto</TableCell>
                              <TableCell>Preço Unitário</TableCell>
                              <TableCell>Sub Total</TableCell>
                              <TableCell align="center">Ações</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {watch('itens')?.map((item, index) => (
                              <TableRow key={index?.toString()}>
                                <TableCell>{item?.referencia}</TableCell>
                                <TableCell>{item?.descricao}</TableCell>
                                <TableCell>{item?.quantidade}</TableCell>
                                <TableCell>
                                  {item?.desconto_vlr?.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </TableCell>
                                <TableCell>
                                  {item?.preco?.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </TableCell>
                                <TableCell>
                                  {item?.subtotal?.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      openDialog(
                                        <ItemModal
                                          item={item}
                                          onSubmit={(values) =>
                                            onEditItem({ values, index })
                                          }
                                        />,
                                        'Editar Item'
                                      )
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    onClick={() => onDeleteItem(index)}
                                    disabled={!Boolean(index)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" color="primary">
                  Total:{' '}
                  {summarizer(watch('itens'), 'subtotal')?.toLocaleString(
                    'pt-br',
                    { style: 'currency', currency: 'BRL' }
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card title="Pagamento">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} display="flex">
                <Card style={styles?.card}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Dropdown
                        name="forma_pagto_id"
                        control={control}
                        label="Forma de Pagamento"
                        options={drops?.FormaDePagamento?.filter(
                          (f) =>
                            f?.modulo !== 'COMPRAS' && f?.modulo !== 'CHEQUES'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Dropdown
                        name="condicao_pagamento_id"
                        control={control}
                        label="Condição de Pagamento"
                        options={findOnArray(
                          watch('forma_pagto_id'),
                          drops?.FormaDePagamento,
                          'condicoes'
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Checkbox
                        name="fixar"
                        control={control}
                        label="Fixar Dia"
                      />
                    </Grid>
                    {Boolean(watch('haver')) && (
                      <Grid item xs={12}>
                        <Checkbox
                          name="haver_uso"
                          control={control}
                          label={`Utilizar Haver (${(
                            watch('haver') || 0
                          )?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })} disponível para uso)`}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} textAlign="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(onChangePagto)}
                      >
                        CALCULAR
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} display="flex">
                <Card style={styles?.card}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Parcela</TableCell>
                              <TableCell>Data</TableCell>
                              <TableCell>Valor</TableCell>
                              <TableCell>Forma de Pagamento</TableCell>
                              <TableCell align="center">Ações</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {watch('pagamento')?.map((item, index) => (
                              <TableRow key={index?.toString()}>
                                <TableCell>{item?.parcela}</TableCell>
                                <TableCell>
                                  {Boolean(item?.dtvenc) &&
                                    moment(item?.dtvenc).isValid() &&
                                    moment(item?.dtvenc).format('DD/MM/YYYY')}
                                </TableCell>
                                <TableCell>
                                  {item?.valor?.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </TableCell>
                                <TableCell>
                                  {findOnArray(
                                    item?.forma_pagto_id,
                                    drops?.FormaDePagamento,
                                    'label'
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      openDialog(
                                        <PagamentoModal
                                          item={item}
                                          onSubmit={(values) =>
                                            onEditPagto({ values, index })
                                          }
                                        />,
                                        'Editar Parcela'
                                      )
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    onClick={() => onDeletePagto(index)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" color="primary">
                  Total:{' '}
                  {summarizer(watch('pagamento'), 'valor')?.toLocaleString(
                    'pt-br',
                    { style: 'currency', currency: 'BRL' }
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Gerar;
